<template>
  <main>
    <div
      class="modal fade"
      id="modalExamenLaboratorio"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Registro de Examen de Laboratorio
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-waves ">
              <div class="card-header bg-light text-dark">
                <i class="fa fa-book fa-fw text-primary"></i>Datos
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Nombre</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="examen.nombre"
                      :class="{
                        'is-invalid':
                          $v.examen.nombre.$error && $v.examen.nombre.$dirty,
                      }"
                    />
                  </div>

                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Abreviación</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="examen.abbr_name"
                      :class="{
                        'is-invalid':
                          $v.examen.abbr_name.$error &&
                          $v.examen.abbr_name.$dirty,
                      }"
                    />
                  </div>

                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Codigo</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="examen.codigo"
                      :class="{
                        'is-invalid':
                          $v.examen.codigo.$error && $v.examen.codigo.$dirty,
                      }"
                    />
                  </div>

                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Unidad</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="examen.unidad"
                      :class="{
                        'is-invalid':
                          $v.examen.unidad.$error && $v.examen.unidad.$dirty,
                      }"
                    />
                  </div>

                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Valor de referencia</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="examen.valores_referencia"
                      :class="{
                        'is-invalid':
                          $v.examen.valores_referencia.$error &&
                          $v.examen.valores_referencia.$dirty,
                      }"
                    />
                  </div>

                  <div class="form-group col-lg-12 col-md-5 col-sm-5">
                    <label class="form-label">Valor minimo</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="examen.valor_minimo"
                    />
                  </div>

                  <div class="form-group col-lg-12 col-md-5 col-sm-5">
                    <label class="form-label">Valor máximo</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="examen.valor_maximo"
                    />
                  </div>

                  <div class="form-group col-lg-12 col-md-5 col-sm-12">
                    <label class="form-label">Tipo</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="examen.tipo"
                      :class="{
                        'is-invalid':
                          $v.examen.tipo.$error && $v.examen.tipo.$dirty,
                      }"
                    >
                      <option value="opcion">Opcion</option>
                      <option value="texto">Texto</option>
                      <option value="numero">Numero</option>
                    </select>
                  </div>

                  <div
                    v-if="examen.tipo == 'opcion'"
                    class="form-group col-lg-12 col-md-4 col-sm-12"
                  >
                    <div class="card-body">
                      <div class="form-group row">
                        <div class="col-8">
                          <label class="form-label">Opcion</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="opci.descripcion"
                            :class="{
                              'is-invalid':
                                $v.opci.descripcion.$error &&
                                $v.opci.descripcion.$dirty,
                            }"
                          />
                        </div>
                        <div class="col-4 mt-4 py-2">
                          <button
                            class="btn btn-primary "
                            @click.prevent="agregarOpcion()"
                            type="button"
                          >
                            Agregar Opcion&nbsp;<i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="form-group">
                        <table class="table table-striped table-sm ">
                          <tr class="table-primary">
                            <th>Opciones</th>
                            <th></th>
                          </tr>

                          <tr v-for="(item, i) in examen.opciones" :key="i">
                            <td>{{ item.descripcion }}</td>
                            <td>
                              <button
                                class="btn btn-outline-danger"
                                @click.prevent="removerOpcion()"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-12 col-md-4 col-sm-12">
                    <label class="form-label">Grupo</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="examen.grupo"
                      :class="{
                        'is-invalid':
                          $v.examen.grupo.$error && $v.examen.grupo.$dirty,
                      }"
                    >
                      <option value="1">Si</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="save()"
            >
              Guardar<i class="fa fa-save fa-fw"></i>
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm"
              @click="cerrar()"
            >
              Cerrar<i class="fa fa-times-circle fa-fw"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ExamenLaboratorioService from "../../../../../services/ExamenLaboratorioService";

import Toast from "../../../../../components/common/utilities/toast";
import { required } from "vuelidate/lib/validators";
import $ from "jquery";

export default {
  data() {
    return {
      examen: {
        id: "",
        nombre: "",
        unidad: "0",
        codigo: "",
        valores_referencia: "0",
        tipo: "",
        grupo: "0",
        id_examen: "",
        id_area: "",
        valor_minimo: "",
        valor_maximo: "",
        opciones: [],
        abbr_name: "",
      },
      opci: {
        descripcion: "",
      },
      type: "store",
    };
  },
  validations() {
    return {
      examen: {
        nombre: { required },
        unidad: { required },
        valores_referencia: { required },
        grupo: { required },
        tipo: { required },
        abbr_name: { required },
        codigo: { required },
      },
      opci: {
        descripcion: { required },
      },
    };
  },
  methods: {
    async save() {
      try {
        this.$v.examen.$touch();

        if (this.$v.examen.$invalid) {
          return;
        }

        this.LoaderSpinnerShow();

        if (this.type === "store") {
          await ExamenLaboratorioService.store(this.examen);
          this.type = "store";
          this.limpiarCampos();
        } else if (this.type === "edit") {
          await ExamenLaboratorioService.update(this.examen);
          this.limpiarCampos();
          this.type = "store";
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });

        this.$emit("cargar-examen", this.examen.id_area);

        $("#modalExamenLaboratorio").modal("hide");
      } catch (err) {
        console.error(err);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
    async limpiarCampos() {
      this.examen.nombre = "";
      //this.examen.unidad = "";
      //this.examen.valores_referencia = "";
      this.examen.valor_minimo = "";
      this.examen.valor_maximo = "";
      this.examen.tipo = "";
      this.examen.abbr_name = "";
      this.examen.codigo = "";
    },

    cerrar() {
      this.limpiarCampos();
      $("#modalExamenLaboratorio").modal("hide");
    },
    async edit(id) {
      this.$nextTick(async () => {
        this.type = "edit";
        $("#modalExamenLaboratorio").modal("show");
        this.examen.id_examen = this.$route.params?.id_examen || null;
        const response = await ExamenLaboratorioService.show(id);
        this.examen.id = response.data.id;
        this.examen.nombre = response.data.nombre;
        this.examen.unidad = response.data.unidad;
        this.examen.valores_referencia = response.data.valores_referencia;
        this.examen.valor_minimo = response.data.valor_minimo;
        this.examen.valor_maximo = response.data.valor_maximo;
        this.examen.abbr_name = response.data.abbr_name;
        this.examen.codigo = response.data.codigo;
        this.examen.tipo = response.data.tipo;
        this.examen.grupo = response.data.grupo;
        this.examen.id_area = response.data.id_area;
        this.examen.opciones = response.data.opciones;
      });
    },
    async modalGuardar(id) {
      this.$nextTick(async () => {
        this.type = "store";
        $("#modalExamenLaboratorio").modal("show");
        this.examen.id_area = id;
        this.examen.id_examen = this.$route.params?.id_examen || null;
      });
    },
    agregarOpcion() {
      this.$v.opci.$touch();
      if (this.$v.opci.$invalid) {
        return;
      }
      this.examen.opciones.push({ ...this.opci });
      this.opci = {};
    },
    removerOpcion(index) {
      this.examen.opciones.splice(index, 1);
    },
  },
};
</script>
