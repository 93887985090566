<template>
    <main>
        <page-header-compact>
            Configuración de Examenes de laboratorio (Area: {{area.nombre }}) 
        </page-header-compact>

        <div class="container-fluid px-4">
            <div class="card mt-4">
                <div class="card-body">
                    <div v-if="id_examen!==null"><h3 class="text-muted">Examen: {{ examen.nombre }}</h3></div>
                    <data-grid :data="pagination"
                           @changePage="changePage($event)"
                           @perPageChange="perPageChange($event)"
                           @search="search($event)">
                            <template #grid>
                                <grid-table>
                                    <template #head>
                                        <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                    </template>
                                    <template #body>
                                        <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                            <grid-cell class="text-center small">
                                                {{item.id}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.nombre}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.abbr_name}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.valores_referencia}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.unidad}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.tipo}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                <span v-if="parseInt(item.grupo) === 1">SI</span>
                                                <span v-else>NO</span>
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                <div v-if="item.sub_examen.length">
                                                    <li v-for="subexamen in item.sub_examen" :key="subexamen.id">{{subexamen.nombre}}</li>
                                                </div>
                                                <span v-else class="badge badge-light badge-marketing">Sin Subexamenes</span>
                                            </grid-cell>

                                            <grid-cell class="text-center small">
                                                {{item.area.nombre}}
                                            </grid-cell>
                                            <grid-cell class="text-center">
                                                <button class="btn btn-transparent btn-sm shadow-xs text-success" type="button" @click="abrirModal(item.id)"  >
                                                      <i class="fas fa-edit fa-fw" ></i>
                                                </button>
                                                <router-link
                                                    v-if="parseInt(item.grupo) === 1" 
                                                    class="btn btn-light btn-xs text-primary"
                                                    type="button" 
                                                    :to="{
                                                        name : 'examen-laboratorio.index',
                                                        params : {
                                                            ...(id_area ? {'id_area' : id_area} : {}),
                                                            ...(parseInt(item.grupo) === 1 ? {'id_examen' : item.id} : {}),
                                                        }
                                                    }">
                                                      <i class="fas fa-cog fa-fw" ></i> Configurar Examenes
                                                </router-link>
                                            </grid-cell>
                                        </grid-row>
                                    </template>
                                </grid-table>
                            </template>
                            <template #action-button>
                                <button @click="abrirModalGuardar()" class="btn btn-info btn-sm shadow-sm mb-2" type="button">
                                    Nuevo &nbsp;<i class="fas fa-plus"></i>
                                </button>
                            </template>
                    </data-grid>

                   <modalExamenLaboratorio ref="modalExamenLaboratorio" v-on:cargar-examen="getExamenLaboratorio(id_area)"></modalExamenLaboratorio>
                </div>
            </div>
           
        </div>

    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../../../components/common/utilities/DataGrid/GridCell";
import Toast from '../../../../../components/common/utilities/toast';
import modalExamenLaboratorio from "./modalExamenLaboratorio";
import ExamenLaboratorioService from '../../../../../services/ExamenLaboratorioService';
import AreaLaboratorioService from '../../../../../services/AreaLaboratorioService';

export default {
    components: {
        PageHeaderCompact,GridCell, GridRow, GridCellHeader, GridTable, DataGrid, modalExamenLaboratorio
    },
    data(){
        return {
            examen:{},
            area:{},
            id_area:'',
            id_examen : null,
            examenlaboratorio:{},
            headers : [
                'Id','Nombre','Abreviación','Valor de referencia','Unidad','Tipo','Grupo','Examenes','Area', 'Opciones'
           ],
           pagination : {
               data : [],
               current_page : 1,
               page : 1,
               per_page : 5,
               search : '',
               total: '',
           }
        }
    },
    methods : {
        async showNameExamen(){
            const response =await ExamenLaboratorioService.show(this.id_examen);
            this.examen=response.data;
        },
        async getArea(id){
            const response = await AreaLaboratorioService.show(id);
            this.area=response.data;
        },
        async getExamenLaboratorio(id){
            try {
                this.LoaderSpinnerShow();
                const response = (await ExamenLaboratorioService.list(id,this.pagination.search, this.pagination, this.id_examen))?.data;
                this.examenlaboratorio = response.data;
                console.log(response.data);
                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();

            }catch (e) {
                console.error(e);
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getExamenLaboratorio(this.id_area);
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getExamenLaboratorio(this.id_area);
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getExamenLaboratorio(this.id_area);
        },
        abrirModal(id){
           this.$refs.modalExamenLaboratorio.edit(id);
        },
        abrirModalGuardar(){
           this.$refs.modalExamenLaboratorio.modalGuardar(this.id_area);
        }

    },
    watch : {
        $route(){
            this.id_examen = this.$route.params?.id_examen || null;
            this.changePage(1);
            
        },
        id_examen(){
            this.showNameExamen();
        }
    },
    async created(){
        this.id_area=this.$route.params.id_area;
        this.id_examen = this.$route.params?.id_examen || null;

        await this.getArea(this.id_area);
        await this.getExamenLaboratorio(this.id_area);
        await this.showNameExamen();
    }
}
</script>
