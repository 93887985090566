var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"modal fade",attrs:{"id":"modalExamenLaboratorio","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card-waves"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.nombre),expression:"examen.nombre"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.examen.nombre.$error && _vm.$v.examen.nombre.$dirty,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.examen.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.examen, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Abreviación")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.abbr_name),expression:"examen.abbr_name"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.examen.abbr_name.$error &&
                        _vm.$v.examen.abbr_name.$dirty,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.examen.abbr_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.examen, "abbr_name", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Codigo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.codigo),expression:"examen.codigo"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.examen.codigo.$error && _vm.$v.examen.codigo.$dirty,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.examen.codigo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.examen, "codigo", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Unidad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.unidad),expression:"examen.unidad"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.examen.unidad.$error && _vm.$v.examen.unidad.$dirty,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.examen.unidad)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.examen, "unidad", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Valor de referencia")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.valores_referencia),expression:"examen.valores_referencia"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.examen.valores_referencia.$error &&
                        _vm.$v.examen.valores_referencia.$dirty,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.examen.valores_referencia)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.examen, "valores_referencia", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-5 col-sm-5"},[_c('label',{staticClass:"form-label"},[_vm._v("Valor minimo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.valor_minimo),expression:"examen.valor_minimo"}],staticClass:"form-control form-control-sm",attrs:{"type":"number"},domProps:{"value":(_vm.examen.valor_minimo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.examen, "valor_minimo", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-5 col-sm-5"},[_c('label',{staticClass:"form-label"},[_vm._v("Valor máximo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.valor_maximo),expression:"examen.valor_maximo"}],staticClass:"form-control form-control-sm",attrs:{"type":"number"},domProps:{"value":(_vm.examen.valor_maximo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.examen, "valor_maximo", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-5 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Tipo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.tipo),expression:"examen.tipo"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.examen.tipo.$error && _vm.$v.examen.tipo.$dirty,
                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.examen, "tipo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"opcion"}},[_vm._v("Opcion")]),_c('option',{attrs:{"value":"texto"}},[_vm._v("Texto")]),_c('option',{attrs:{"value":"numero"}},[_vm._v("Numero")])])]),(_vm.examen.tipo == 'opcion')?_c('div',{staticClass:"form-group col-lg-12 col-md-4 col-sm-12"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-8"},[_c('label',{staticClass:"form-label"},[_vm._v("Opcion")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.opci.descripcion),expression:"opci.descripcion"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.$v.opci.descripcion.$error &&
                              _vm.$v.opci.descripcion.$dirty,
                          },attrs:{"type":"text"},domProps:{"value":(_vm.opci.descripcion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.opci, "descripcion", $event.target.value)}}})]),_c('div',{staticClass:"col-4 mt-4 py-2"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.agregarOpcion()}}},[_vm._v(" Agregar Opcion "),_c('i',{staticClass:"fa fa-plus"})])])]),_c('div',{staticClass:"form-group"},[_c('table',{staticClass:"table table-striped table-sm"},[_vm._m(2),_vm._l((_vm.examen.opciones),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.descripcion))]),_c('td',[_c('button',{staticClass:"btn btn-outline-danger",on:{"click":function($event){$event.preventDefault();return _vm.removerOpcion()}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])})],2)])])]):_vm._e(),_c('div',{staticClass:"form-group col-lg-12 col-md-4 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Grupo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.examen.grupo),expression:"examen.grupo"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.examen.grupo.$error && _vm.$v.examen.grupo.$dirty,
                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.examen, "grupo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Si")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])])])])])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar"),_c('i',{staticClass:"fa fa-save fa-fw"})]),_c('button',{staticClass:"btn btn-light btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.cerrar()}}},[_vm._v(" Cerrar"),_c('i',{staticClass:"fa fa-times-circle fa-fw"})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Registro de Examen de Laboratorio ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-light text-dark"},[_c('i',{staticClass:"fa fa-book fa-fw text-primary"}),_vm._v("Datos ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"table-primary"},[_c('th',[_vm._v("Opciones")]),_c('th')])
}]

export { render, staticRenderFns }